<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-text-field
            :error-messages="nameErrors"
            :label="$t('name')"
            @blur="$v.item.name.$touch()"
            @input="$v.item.name.$touch()"
            v-model="item.name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-text-field
            :error-messages="ibanErrors"
            :label="$t('iban')"
            @blur="$v.item.iban.$touch()"
            @input="$v.item.iban.$touch()"
            v-model="item.iban"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-text-field
            :error-messages="bicErrors"
            :label="$t('bic')"
            @blur="$v.item.bic.$touch()"
            @input="$v.item.bic.$touch()"
            v-model="item.bic"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-text-field
            :error-messages="instituteErrors"
            :label="$t('institute')"
            @blur="$v.item.institute.$touch()"
            @input="$v.item.institute.$touch()"
            v-model="item.institute"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-checkbox
            :label="$t('default')"
            @blur="$v.item.enabled.$touch()"
            @input="$v.item.enabled.$touch()"
            v-model="item.default"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-checkbox
            :label="$t('active')"
            @blur="$v.item.active.$touch()"
            @input="$v.item.active.$touch()"
            v-model="item.active"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import { validationMixin } from "vuelidate";
  import has from "lodash/has";
  import { required } from "vuelidate/lib/validators";

  export default {
    name: "BankAccountForm",
    mixins: [validationMixin],
    props: {
      values: {
        type: Object,
        required: true,
      },
      errors: {
        type: Object,
        default: () => { },
      },

      loading: {
        type: Boolean,
        required: true,
      },

      initialValues: {
        type: Object,
        default: () => { },
      },

      handleSubmit: {
        type: Function,
        required: false,
      },

      handleReset: {
        type: Function,
        required: false,
      },
    },
    mounted() { },
    data() {
      return {
        name: null,
        text: null,
      };
    },
    computed: {
      item() {
        return this.initialValues || this.values;
      },
      nameErrors() {
        const errors = [];
        if (!this.$v.item.name.$dirty) {
          return errors;
        }
        has(this.violations, "name") && errors.push(this.violations.name);
        !this.$v.item.name.required && errors.push(this.$t("Field is required"));

        return errors;
      },
      ibanErrors() {
        const errors = [];
        if (!this.$v.item.iban.$dirty) {
          return errors;
        }
        has(this.violations, "iban") && errors.push(this.violations.iban);
        !this.$v.item.iban.required && errors.push(this.$t("Field is required"));
        return errors;
      },
      bicErrors() {
        const errors = [];
        if (!this.$v.item.bic.$dirty) {
          return errors;
        }
        has(this.violations, "bic") && errors.push(this.violations.bic);
        !this.$v.item.bic.required && errors.push(this.$t("Field is required"));
        return errors;
      },
      instituteErrors() {
        const errors = [];
        if (!this.$v.item.institute.$dirty) {
          return errors;
        }
        has(this.violations, "institute") && errors.push(this.violations.institute);
        !this.$v.item.institute.required && errors.push(this.$t("Field is required"));
        return errors;
      },
      violations() {
        return this.errors || {};
      },
    },
    methods: {
      submitItem() {
        if (this.handleSubmit) {
          this.handleSubmit();
        }
      },
      resetItem() {
        if (this.handleReset) {
          this.handleReset();
        }
      },
    },
    validations: {
      item: {
        name: {
          required,
        },
        iban: {
          required,
        },
        bic: {
          required,
        },
        institute: {
          required,
        },
      },
    },
  };
</script>
